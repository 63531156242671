import axios from '../request'

// 订单相关接口
export default {
  getList(params) {
    return axios({ name: '订单列表', method: 'get', url: '/serve-order/api/v1/p_order', params })
  },

  createOrder(data) {
    return axios({ name: '创建订单', method: 'post', url: '/serve-order/api/v1/p_order', data })
  },

  cancelOrder(id) {
    return axios({ name: '取消订单', method: 'delete', url: `/serve-order/api/v1/p_order/${id}` })
  }
}
