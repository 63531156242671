import axios from '../request'

// app管理相关接口
export default {
  getVersionInfo(type) {
    return axios({ name: 'APP版本信息', method: 'get', url: `/serve-user/api/v1/app/version/${type}` })
  },

  updateVersion(data) {
    return axios({ name: '更新APP版本信息', method: 'post', url: '/serve-user/api/v1/app/version', data })
  },

  getLunchPage(type) {
    return axios({ name: '获取APP启动页', method: 'get', url: 'serve-user/api/v1/app/version/3' })
  },

  updateLunchPage(data) {
    return axios({ name: '更新APP启动页', method: 'post', url: '/serve-user/api/v1/app/cover', data })
  },

  getLifeServiceList(params) {
    return axios({ name: '生活服务列表', method: 'get', url: '/eage-admin/api/v1/config', params })
  },

  createLifeService(data) {
    return axios({ name: '创建生活服务', method: 'post', url: '/eage-admin/api/v1/config', data })
  },

  editLifeService(data) {
    return axios({ name: '修改生活服务', method: 'put', url: '/eage-admin/api/v1/config', data })
  },

  deleteLifeService(id) {
    return axios({ name: '删除生活服务', method: 'delete', url: `/eage-admin/api/v1/config/${id}` })
  }
}
