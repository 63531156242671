import axios from '../request'

// 角色相关接口
export default {
  getList(params) {
    return axios({ name: '角色列表', method: 'get', url: '/eage-admin/api/v1/roles/page', params })
  },

  createRole(data) {
    return axios({ name: '创建角色', method: 'post', url: '/eage-admin/api/v1/roles', data })
  },

  deleteRole(id) {
    return axios({ name: '删除角色', method: 'delete', url: `/eage-admin/api/v1/roles/${id}` })
  },

  getRoleMenu(id) {
    return axios({
      name: '获取角色菜单',
      method: 'get',
      url: `/eage-admin/api/v1/roles/${id}/menus`
    })
  },

  getRoleMenuPerm(data) {
    return axios({
      name: '获取角色菜单权限',
      method: 'get',
      url: `/eage-admin/api/v1/roles/${data.roleId}/permissions`,
      data
    })
  },

  updateRoleMenu(data) {
    return axios({
      name: '更新角色菜单',
      method: 'put',
      url: `/eage-admin/api/v1/roles/${data.id}/menus`,
      data
    })
  },

  updateRoleMenuPerm(data) {
    return axios({
      name: '更新角色菜单权限',
      method: 'put',
      url: `/eage-admin/api/v1/roles/${data.roleId}/permissions`,
      data
    })
  }
}
