import axios from 'axios'
import router from '@/router'
import store from '@/store'
import { Message } from 'element-ui'

// 全局配置
axios.defaults.headers = {
  // 'Content-Type': 'application/json;charset=UTF-8',
  error_message: true // 错误消息提示
}

// 实例配置
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 1000 * 10
  // headers: { Authorization: localStorage.getItem('token') || '' }
})

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么
    const token = localStorage.getItem('token')
    if (!config.headers.Authorization && token) {
      config.headers.Authorization = token
    }

    return config
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

let isRefreshing = false // 标志当前是否正在刷新token
let requestList = [] // 缓存的请求队列

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    const { data } = response

    if (data.code !== '00000') {
      if (response.config.headers.error_message) {
        // Message.closeAll()
        Message.error(data.msg || '系统错误') // 提示错误消息
      }
    }

    return data
  },
  (error) => {
    // 对响应错误做点什么
    if (error.response) {
      const { data } = error.response

      if (data.code === 'A0230') {
        const { config } = error.response
        // 控制重复获取token
        if (!isRefreshing) {
          isRefreshing = true
          return axios({
            method: 'post',
            url: '/eage-auth/oauth/token',
            baseURL: process.env.VUE_APP_BASE_API,
            timeout: 1000 * 10,
            params: { grant_type: 'refresh_token', refresh_token: localStorage.getItem('refresh_token') },
            headers: { Authorization: 'Basic ZWFnZS1hZG1pbi13ZWI6MTIzNDU2' }
          })
            .then(({ data: res }) => {
              if (res.code === '00000') {
                const newToken = res.data.token_type + ' ' + res.data.access_token

                localStorage.setItem('token', newToken)
                localStorage.setItem('refresh_token', res.data.refresh_token)

                // 执行requests队列中的请求（requests中存的不是请求参数，而是请求的Promise函数，这里直接拿来执行就好）
                requestList.forEach((run) => run(newToken))
                // 将请求队列置空
                requestList = []
                // 重新执行当前未执行成功的请求并返回
                config.headers.Authorization = newToken
                return instance(config)
              } else {
                loginAgain()
              }
            })
            .catch(() => {
              loginAgain()
            })
            .finally((isRefreshing = false))
        } else {
          return new Promise((resolve) => {
            // 这里加入的是一个promise的解析函数，将响应的config配置对应解析的请求函数存到requests中，等到刷新token回调后再执行
            requestList.push((newToken) => {
              config.headers.Authorization = newToken
              resolve(instance(config))
            })
          })
        }
      } else {
        Message.error(data.msg || '系统错误')
      }
    }
    return error.response.data
    // return Promise.reject(error)
  }
)

// 重新登陆
function loginAgain() {
  localStorage.clear()
  store.commit('user/logout')
  router.replace({ path: '/login' })
}

export default instance
