// 用户数据

import loginApi from '@/api/modules/login'

const state = {
  token: null,
  userInfo: {}, // 用户信息
  roles: [], // 角色集合
  perms: [] // 权限集合
}

const mutations = {
  setToken(state, payload) {
    state.token = payload
  },
  deleteToken(state) {
    state.token = {}
  },

  setUserInfo(state, payload) {
    state.userInfo = payload
  },
  setRoles(state, payload) {
    state.roles = payload
  },
  setPerms(state, payload) {
    state.perms = payload
  },

  logout(state) {
    state.token = null
    state.userInfo = {}
    state.roles = []
    state.perms = []
  }
}

const getters = {}

const actions = {
  async getUserInfo(context) {
    const res = await loginApi.getUserInfo()

    const userInfo = { id: res.data.id, nickname: res.data.nickname, avatar: res.data.avatar }
    context.commit('setUserInfo', userInfo)
    context.commit('setRoles', res.data.roles)
    context.commit('setPerms', res.data.perms)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
