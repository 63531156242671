import axios from '../request'

// 权限相关接口
export default {
  getList(params) {
    return axios({ name: '菜单权限列表', method: 'get', url: '/eage-admin/api/v1/permissions', params })
  },

  getDetail(id) {
    return axios({ name: '权限详情', method: 'get', url: `/eage-admin/api/v1/permissions/${id}` })
  },

  createPerm(data) {
    return axios({ name: '新增权限', method: 'post', url: '/eage-admin/api/v1/permissions', data })
  },

  updatePerm(data) {
    return axios({ name: '更新权限', method: 'put', url: `/eage-admin/api/v1/permissions/${data.id}`, data })
  },

  deletePerm(ids) {
    return axios({ name: '删除权限', method: 'delete', url: `/eage-admin/api/v1/permissions/${ids}` })
  }
}
