import axios from '../request'

// 菜单相关接口
export default {
  getList() {
    return axios({ name: '菜单树状列表', method: 'get', url: '/eage-admin/api/v1/menus/table' })
  },

  getSelectList() {
    return axios({ name: '菜单下拉层级列表', method: 'get', url: '/eage-admin/api/v1/menus/select' })
  },

  getRouteList() {
    return axios({ name: '菜单路由层级列表', method: 'get', url: '/eage-admin/api/v1/menus/route' })
  },

  createMenu(data) {
    return axios({ name: '创建菜单', method: 'post', url: '/eage-admin/api/v1/menus', data })
  },

  updateMenu(data) {
    return axios({ name: '更新菜单', method: 'put', url: `/eage-admin/api/v1/menus/${data.id}`, data })
  },

  deleteMenu(id) {
    return axios({ name: '删除菜单', method: 'delete', url: `/eage-admin/api/v1/menus/${id}` })
  }
}
