import axios from '../request'

// 文件上传相关接口
export default {
  uploadFile(data) {
    return axios({ name: '上传文件', method: 'post', url: '/serve-user/app-api/v1/upload', data, timeout: 1000 * 120 })
  },

  uploadFirmware(data) {
    return axios({ name: '上传固件', method: 'post', url: '/serve-user/app-api/v1/upload/firmware', data })
  }
}
