import axios from '../request'

// 产品相关接口
export default {
  // 产品
  getProductList(params) {
    return axios({ name: '产品列表', method: 'get', url: '/serve-connect/api/v1/product', params })
  },

  getProductDetail(id) {
    return axios({ name: '产品详情', method: 'get', url: `/serve-connect/api/v1/product/${id}` })
  },

  createProduct(data) {
    return axios({ name: '新增产品', method: 'post', url: '/serve-connect/api/v1/product', data })
  },

  editProduct(data) {
    return axios({ name: '修改产品', method: 'put', url: '/serve-connect/api/v1/product', data })
  },

  deleteProduct(id) {
    return axios({ name: '删除产品', method: 'delete', url: `/serve-connect/api/v1/product/${id}` })
  },

  setProductShelfStatus(id) {
    return axios({ name: '产品上下架', method: 'put', url: `/serve-connect/api/v1/product/${id}` })
  },

  // 产品库
  getLibraryList(params) {
    return axios({ name: '产品库列表', method: 'get', url: '/serve-connect/api/v1/product/baseList', params })
  },

  editLibraryProduct(data) {
    return axios({ name: '新增/修改产品库', method: 'post', url: '/serve-connect/api/v1/product/updateBase', data })
  },

  deleteLibraryProduct(id) {
    return axios({ name: '删除产品库', method: 'delete', url: `/serve-connect/api/v1/product/base/${id}` })
  }
}
