import axios from '../request'

// 用户相关接口
export default {
  getList(params) {
    return axios({ name: '用户列表', method: 'get', url: '/serve-user/api/v1/users', params })
  },

  getDetail(id) {
    return axios({ name: '用户详情', method: 'get', url: `/serve-user/api/v1/users/${id}` })
  },

  banUser(id) {
    return axios({ name: '禁用用户', method: 'put', url: `/serve-user/api/v1/users/forbidden/${id}` })
  },

  getFeedbackList(params) {
    return axios({ name: '用户反馈列表', method: 'get', url: '/serve-user/api/v1/users/feedback', params })
  }
}
