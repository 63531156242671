/**
 * @description: 节流
 * @param {function} fn 要节流的函数
 * @param {number} delay 延迟时间
 * @param {string} event 事件类型
 * 例：<el-button v-throttle="{ fn: test, delay: 500, event: 'click' }">test</el-button>
 * 传参时：<el-button v-throttle="{ fn: () => test(a, b, c) }">test</el-button>
 */
export default {
  inserted: function (el, binding, vnode) {
    const { fn, delay = 1000, event = 'click' } = binding.value

    let t1 = null

    el.handler = () => {
      const t2 = new Date()

      if (t2 - t1 >= delay) {
        fn()
        t1 = t2
      }
    }

    el.addEventListener(event, el.handler)
  }
}
