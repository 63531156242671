/**
 * @description: 获取数据的具体类型
 * @param {*} 要判断的数据
 * @return {String} 具体类型
 */
export function getDataType(data) {
  if (data instanceof Element) {
    return 'element'
  }
  return Object.prototype.toString.call(data).slice(8, -1).toLowerCase()
}

/**
 * @description: 深拷贝
 * @param {*} 要拷贝的数据
 * @return {*} 拷贝后的数据
 */
export function deepClone(data) {
  const type = getDataType(data)

  let newData
  if (type === 'array') {
    newData = []
    for (let i = 0, len = data.length; i < len; i++) {
      // newData.push(deepClone(data[i]))
      newData[i] = deepClone(data[i])
    }
  } else if (type === 'object') {
    newData = {}
    for (const key in data) {
      // if (Object.prototype.hasOwnProperty.call(data, key)) { // 过滤掉原型身上的属性
      newData[key] = deepClone(data[key])
      // }
    }
  } else {
    if (type === 'date') return new Date(data)
    if (type === 'regexp') return new RegExp(data)
    return data
  }
  return newData
}

// 防抖和节流建议使用自定义指令的方式更加便捷，此处仅做备份方法
/**
 * @description: 函数防抖
 * @param {function} fn 要防抖的函数
 * @param {number} delay 延迟时间
 * @param {boolean} immediate 是否立即执行，默认为 false
 * @return {function}
 */
export function debounce(fn, delay, immediate) {
  let timer = null

  return function () {
    if (timer) clearTimeout(timer)
    if (immediate) {
      const callNow = !timer
      timer = setTimeout(() => {
        timer = null
      }, delay)
      if (callNow) fn.call(this, ...arguments)
    } else {
      timer = setTimeout(() => {
        fn.call(this, ...arguments)
        timer = null
      }, delay)
    }
  }
}
// 防抖和节流要在文件中单独引入后以这种方式使用
// test: debounce(function (a, b) {
//   console.log(a, b)
// }, 1000),

/**
 * @description: 函数节流
 * @param {function} fn 要节流的函数
 * @param {number} delay 延迟时间
 * @return {function}
 */
export function throttle(fn, delay) {
  let t1 = null

  return function () {
    const t2 = new Date()

    if (t2 - t1 >= delay) {
      fn.call(this, ...arguments)
      t1 = t2
    }
  }
}

/**
 * @description: 重置表单
 * @param {string} refName ref名称
 */
export function resetForm(_this, refName) {
  if (_this.$refs[refName]) {
    _this.$refs[refName].resetFields()
  }
}

/**
 * @description: 数组对象中的 字符串 转 数组
 * @param {array} list 数组对象
 * @param {string} keyName 键名
 * @param {string} delimiter 分割符，默认为','
 * @return {array} 转换后的数组
 */
export function stringToArray(list, keyName, delimiter) {
  for (let i = 0, len = list.length; i < len; i++) {
    if (list[i][keyName]) {
      list[i][keyName] = list[i][keyName].split(delimiter || ',')
    } else {
      list[i][keyName] = []
    }
  }
  return list
}

export default { getDataType, deepClone, debounce, throttle, resetForm, stringToArray }
