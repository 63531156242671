import axios from '../request'

// 设备相关接口
export default {
  getTestList(params) {
    return axios({ name: '测试设备列表', method: 'get', url: '/serve-connect/api/v1/check', params })
  },

  sendControlSignal(data) {
    return axios({ name: '发送控制信号检测设备', method: 'post', url: '/serve-connect/api/v1/check/send', data })
  },

  testDeviceTEMP(data) {
    return axios({ name: '测试温湿度结果', method: 'post', url: '/serve-connect/api/v1/check/humiture', data })
  },

  testDeviceHistory(params) {
    return axios({ name: '历史检测记录', method: 'get', url: '/serve-connect/api/v1/check/logs', params })
  },

  finishTest(data) {
    return axios({ name: '完成测试', method: 'post', url: '/serve-connect/api/v1/check', data })
  }
}
