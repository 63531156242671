import axios from '../request'

// 产品（工厂端）相关接口
export default {
  getList(params) {
    return axios({ name: '产品列表', method: 'get', url: '/serve-connect/api/v1/check/pros', params })
  },

  getDetail(id) {
    return axios({ name: '产品详情', method: 'get', url: `/serve-connect/api/v1/product/detail/${id}` })
  },

  exportPackSerial(data) {
    return axios({ name: '导出产品包装序列号', method: 'post', url: '/serve-order/api/v1/p_order/export', data })
  }
}
