import axios from '../request'

// 固件相关接口
export default {
  getVersionInfo(proNo) {
    return axios({ name: '版本信息', method: 'get', url: `/serve-connect/api/v1/product/version/${proNo}` })
  },

  updateVersion(data) {
    return axios({ name: '更新版本', method: 'post', url: '/serve-connect/api/v1/product/version', data })
  }
}
