// 权限数据

import { constantRoutes } from '@/router'
import menuApi from '@/api/modules/menu'

const state = {
  routes: [],
  addRoutes: []
}

const hasPermission = (roles, route) => {
  if (route.meta && route.meta.roles) {
    if (roles.includes('ROOT')) {
      return true
    }
    return roles.some((role) => {
      if (route.meta?.roles !== undefined) {
        return route.meta.roles.includes(role)
      }
    })
  }
  return false
}

// 路由懒加载
export const loadView = (view) => {
  return (resolve) => require([`@/views/${view}`], resolve)
}

export const filterAsyncRoutes = (routes, roles) => {
  const res = []
  routes.forEach((route) => {
    const tmp = { ...route }
    if (hasPermission(roles, tmp)) {
      if (tmp.component === 'Layout') {
        tmp.component = () => import('@/views/layout/Layout.vue')
      } else {
        if (tmp.component) {
          tmp.component = loadView(tmp.component)
        } else {
          tmp.component = () => import('@/views/error/404.vue')
        }
      }
      res.push(tmp)

      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles)
      }
    }
  })
  return res
}

const mutations = {
  setRoutes(state, payload) {
    state.addRoutes = payload
    state.routes = [...constantRoutes, ...payload]
  }
}

const getters = {}

const actions = {
  generateRoutes(context, roles) {
    return new Promise((resolve, reject) => {
      menuApi
        .getRouteList()
        .then((response) => {
          const asyncRoutes = response.data
          const accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)
          context.commit('setRoutes', accessedRoutes)
          resolve(accessedRoutes)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
