import axios from '../request'

// 设备相关接口
export default {
  getList(params) {
    return axios({ name: '设备列表', method: 'get', url: '/serve-connect/api/v1/device', params })
  },

  forcedUpdating(data) {
    return axios({ name: '推送设备更新', method: 'post', url: '/serve-connect/api/v1/command/update', data })
  },

  deleteDevice(id) {
    return axios({ name: '删除设备', method: 'delete', url: `/serve-connect/api/v1/device/${id}` })
  }
}
