import axios from '../request'

// 自动化相关接口
export default {
  // 分类
  getCategoryList(params) {
    return axios({ name: '分类列表', method: 'get', url: '/serve-connect/api/v1/scene/category', params })
  },

  editCategory(data) {
    return axios({ name: '编辑分类', method: 'post', url: '/serve-connect/api/v1/scene/category', data })
  },

  setCategoryShowHide(id) {
    return axios({ name: '分类 显示/隐藏', method: 'put', url: `/serve-connect/api/v1/scene/category/${id}` })
  },

  deleteCategory(id) {
    return axios({ name: '删除分类', method: 'delete', url: `/serve-connect/api/v1/scene/category/${id}` })
  },

  // 场景/自动化
  getSceneList(params) {
    return axios({ name: '场景列表', method: 'get', url: '/serve-connect/api/v1/scene', params })
  },

  getSceneDetail(id) {
    return axios({ name: '场景详情', method: 'get', url: `/serve-connect/api/v1/scene/${id}` })
  },

  editScene(data) {
    return axios({ name: '编辑场景', method: 'post', url: '/serve-connect/api/v1/scene', data })
  },

  setSceneShelfStatus(id) {
    return axios({ name: '场景上下架', method: 'put', url: `/serve-connect/api/v1/scene/${id}` })
  },

  sortScene(data) {
    return axios({ name: '排序场景', method: 'post', url: '/serve-connect/api/v1/scene/sort', data })
  },

  deleteScene(id) {
    return axios({ name: '删除场景', method: 'delete', url: `/serve-connect/api/v1/scene/${id}` })
  },

  // 传感器
  getSensorTypeList(params) {
    return axios({ name: '传感器列表', method: 'get', url: '/serve-connect/api/v1/scene/sensor', params })
  }
}
