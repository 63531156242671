import { Message } from 'element-ui'

/**
 * @description: 复制文本到剪贴板
 * @param {string} value 要复制的文本
 * 例：v-copy="value"
 */
export default {
  // bind 钩子函数，第一次绑定时调用，可以在这里做初始化设置
  // el: 作用的 dom 对象
  // value: 传给指令的值，也就是要 copy 的值
  bind(el, { value }) {
    el.$value = value // 用一个全局属性来存传进来的值，因为这个值在别的钩子函数里还会用到

    el.handler = async () => {
      if (!el.$value) return Message.warning('无复制内容') // 值为空的时候，给出提示

      const textarea = document.createElement('textarea') // 创建元素用于复制
      // 将该 textarea 设为 readonly 防止 iOS 下自动唤起键盘，同时将 textarea 移出可视区域
      textarea.readOnly = 'readonly'
      textarea.style.position = 'absolute'
      textarea.style.left = '-9999px'
      textarea.value = el.$value // 赋值给 textarea 标签的 value 属性
      document.body.appendChild(textarea) // 将元素插入页面进行调用
      textarea.select() // 选中对象
      const result = document.execCommand('Copy') // 执行浏览器复制命令
      if (result) Message.success('复制成功')
      document.body.removeChild(textarea) // 删除元素

      // try {
      //   await navigator.clipboard.writeText(el.$value)
      //   Message.success('复制成功')
      // } catch (err) {
      //   console.error('Failed to copy: ', err)
      //   Message.error('您的浏览器不支持复制功能')
      // }
    }

    // 绑定点击事件，就是所谓的一键 copy
    el.addEventListener('click', el.handler)
  },

  // 当传进来的值更新的时候触发
  componentUpdated(el, { value }) {
    el.$value = value
  },

  // 指令与元素解绑的时候，移除事件绑定
  unbind(el) {
    el.removeEventListener('click', el.handler)
  }
}
