<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },

  created() {},

  methods: {}
}
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
}
</style>
