import axios from '../request'

// 语音控制相关接口
export default {
  getList(params) {
    return axios({ name: '语音列表', method: 'get', url: '/serve-connect/api/v1/voice', params })
  },

  editVoice(data) {
    return axios({ name: '编辑语音', method: 'post', url: '/serve-connect/api/v1/voice', data })
  },

  deleteVoice(id) {
    return axios({ name: '删除语音', method: 'delete', url: `/serve-connect/api/v1/voice/${id}` })
  }
}
