import axios from '../request'

// 文件下载相关接口
export default {
  downloadSecurityCode(proNo) {
    return axios({
      name: '下载产品防伪码',
      method: 'get',
      url: `/serve-user/api/v1/users/excel/${proNo}`,
      responseType: 'blob'
    })
  },

  getQRCode(params) {
    return axios({
      name: '获取产品二维码',
      method: 'get',
      url: '/serve-user/app-api/v1/ignore/proCode',
      params
      // responseType: 'blob'
    })
  }
}
