import router from '@/router'
import store from '@/store'
// import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

NProgress.configure({ showSpinner: false }) // 进度环显示/隐藏

const whiteList = ['/login', '/register', '/404']

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  if (localStorage.getItem('token')) {
    if (to.path === '/login') {
      next('/')
    } else {
      const roles = store.state.user.roles
      if (roles.length > 0) {
        next()
      } else {
        await store.dispatch('user/getUserInfo')
        const roles = store.state.user.roles
        const accessRoutes = await store.dispatch('permission/generateRoutes', roles)
        accessRoutes.forEach((route) => {
          router.addRoute(route)
        })
        next({ ...to, replace: true })
      }
    }
    next()
  } else {
    if (whiteList.includes(to.path)) {
      next()
    } else {
      next('/login')
    }
  }
  NProgress.done()
})

router.afterEach(() => {
  NProgress.done()
})

// 登录的时候进行筛选
