import axios from '../request'

// 账号管理相关接口
export default {
  getList(params) {
    return axios({ name: '账号列表', method: 'get', url: '/eage-admin/api/v1/users', params })
  },

  getDeveloperSelectList(params) {
    return axios({ name: '开发者下拉列表', method: 'get', url: '/eage-admin/api/v1/users/screen', params })
  },

  getDetail(id) {
    return axios({ name: '账号详情', method: 'get', url: `/eage-admin/api/v1/users/${id}` })
  },

  createAccount(data) {
    return axios({ name: '创建账号', method: 'post', url: '/eage-admin/api/v1/users', data })
  },

  updateAccount(data) {
    return axios({ name: '更新账号', method: 'put', url: `/eage-admin/api/v1/users/${data.id}`, data })
  },

  deleteAccount(id) {
    return axios({ name: '删除账号', method: 'delete', url: `/eage-admin/api/v1/users/${id}` })
  },

  resetPassword(id) {
    return axios({ name: '重置密码', method: 'patch', url: `/eage-admin/api/v1/users/${id}` })
  },

  changePassword(data) {
    return axios({ name: '修改密码', method: 'post', url: '/eage-admin/api/v1/users/modifyPassword', data })
  },

  banAccount(id) {
    return axios({ name: '禁用账号', method: 'put', url: `/eage-admin/api/v1/users/forbidden/${id}` })
  }
}
