import axios from '../request'

// 管理员账号相关接口
export default {
  login(params) {
    return axios({
      name: '登入',
      method: 'post',
      url: '/eage-auth/oauth/token',
      params,
      headers: { Authorization: 'Basic ZWFnZS1hZG1pbi13ZWI6MTIzNDU2' }
    })
  },

  logout() {
    return axios({ name: '登出', method: 'delete', url: '/eage-auth/oauth/logout' })
  },

  register(data) {
    return axios({
      name: '注册',
      method: 'post',
      url: '/eage-admin/api/v1/users/register',
      data,
      headers: { Authorization: 'Basic ZWFnZS1hZG1pbi13ZWI6MTIzNDU2' }
    })
  },

  getValidateCode() {
    return axios({ name: '获取验证码', method: 'get', url: '/captcha' })
  },

  getSmsCode(params) {
    return axios({
      name: '获取短信验证码',
      method: 'post',
      url: '/eage-auth/sms-code',
      params,
      headers: { Authorization: 'Basic ZWFnZS1hcHA6MTIzNDU2' }
    })
  },

  refreshToken(data) {
    return axios({
      name: '刷新token',
      method: 'post',
      url: '/eage-auth/oauth/token',
      data,
      headers: { Authorization: 'Basic ZWFnZS1hZG1pbi13ZWI6MTIzNDU2' }
    })
  },

  getUserInfo() {
    return axios({ name: '获取当前用户信息', method: 'get', url: '/eage-admin/api/v1/users/me' })
  },

  verifyCurrentMobile(data) {
    return axios({ name: '验证当前手机号', method: 'put', url: '/eage-admin/api/v1/users/rebind/send', data })
  },

  verifyNewMobile(data) {
    return axios({ name: '验证新手机号', method: 'put', url: '/eage-admin/api/v1/users/rebind', data })
  }
}
