import Vue from 'vue'

import { linkWays, networkWays, payTypes, orderStatus } from '@/utils/data'

// 通讯方式过滤
function linkWayFilter(value) {
  const obj = linkWays.find((item) => item.value === value)
  return obj ? obj.label : '未知'
}

// 配网方式过滤
function networkWayFilter(value) {
  const obj = networkWays.find((item) => item.value === value)
  return obj ? obj.label : '未知'
}

// 支付方式过滤
function payTypeFilter(value) {
  const obj = payTypes.find((item) => item.value === value)
  return obj ? obj.label : '未知'
}

// 订单状态过滤
function orderStatusFilter(value) {
  const obj = orderStatus.find((item) => item.value === value)
  return obj ? obj.label : '未知'
}

const filters = {
  linkWayFilter,
  networkWayFilter,
  payTypeFilter,
  orderStatusFilter
}

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})
