import axios from '../request'

// 子产品相关接口
export default {
  // 分类
  getCategoryList(params) {
    return axios({ name: '分类列表', method: 'get', url: '/serve-connect/api/v1/category', params })
  },

  editCategory(data) {
    return axios({ name: '分类 新增/修改', method: 'post', url: '/serve-connect/api/v1/category', data })
  },

  setCategoryShowHide(id) {
    return axios({ name: '分类 显示/隐藏', method: 'put', url: `/serve-connect/api/v1/category/${id}` })
  },

  deleteCategory(id) {
    return axios({ name: '分类 显示/隐藏', method: 'delete', url: `/serve-connect/api/v1/category/${id}` })
  },

  // 遥控
  getRemoteList(params) {
    return axios({ name: '遥控列表', method: 'get', url: '/serve-connect/api/v1/category/remote', params })
  },

  editRemote(data) {
    return axios({ name: '遥控 新增/修改', method: 'post', url: '/serve-connect/api/v1/category/remote', data })
  },

  setRemoteShow(id) {
    return axios({ name: '遥控 显示/隐藏', method: 'put', url: `/serve-connect/api/v1/category/remote/${id}` })
  },

  deleteRemote(id) {
    return axios({ name: '遥控 显示/隐藏', method: 'delete', url: `/serve-connect/api/v1/category/remote/${id}` })
  }
}
