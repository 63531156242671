/**
 * @description: 按钮级权限鉴定
 * @param {string} value 要鉴定的权限
 * 例：v-perm="'product:list:create'"
 */
export default {
  inserted: function (el, binding, vnode) {
    // 获取存放在 store 中的数据
    const permList = vnode.context.$store.state.user.perms

    // 当前按钮传递的值( v-perm="'xxx'" 中的值)
    const value = binding.value

    // 判断是否存在对应的按钮权限
    const flag = permList.includes(value)

    // 没有则删除该按钮 (el.parentNode 获取当前节点的父节点)
    if (!flag) {
      el.parentNode.removeChild(el)
    }
  }
}
