import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './static/css/index.css' // 全局样式
// import './static/css/normalize.css'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './static/css/element.css' // 自定义样式，放到 element 下面就会覆盖上面 element 的样式

import dayjs from 'dayjs'
import clipboard from 'clipboard'

import utils from './utils'
import api from './api' // 导入api接口
import './utils/permission' // 路由守卫权限拦截
import './directives' // 自定义指令
import './filters' // 过滤器

Vue.use(ElementUI)

Vue.prototype.$utils = utils
Vue.prototype.$api = api // 将api挂载到vue的原型上
Vue.prototype.$dayjs = dayjs
Vue.prototype.$clipboard = clipboard

Vue.component('OperationBar', () => import('@/components/OperationBar.vue'))
Vue.component('PaginationBar', () => import('@/components/PaginationBar.vue'))
Vue.component('ConfirmPopover', () => import('@/components/ConfirmPopover.vue'))
Vue.component('UploadImg', () => import('@/components/UploadImg.vue'))
Vue.component('UploadImgs', () => import('@/components/UploadImgs.vue'))
Vue.component('UploadVideo', () => import('@/components/UploadVideo.vue'))
Vue.component('UploadFile', () => import('@/components/UploadFile.vue'))
Vue.component('UploadFiles', () => import('@/components/UploadFiles.vue'))
Vue.component('Editor', () => import('@/components/Editor.vue'))
Vue.component('SmsCodeBtn', () => import('@/components/SmsCodeBtn.vue'))

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
