// 需要保存的 产品、用户等等等的详情数据

const state = {
  detail: {}
}

const mutations = {
  setDetail(state, payload) {
    state.detail = payload
  },
  deleteDetail(state) {
    state.detail = {}
  }
}

const getters = {}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
