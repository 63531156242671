import axios from '../request'

// 菜单相关接口
export default {
  getList(params) {
    return axios({ name: '套餐列表', method: 'get', url: '/serve-connect/api/v1/package', params })
  },

  getMainProductList() {
    return axios({ name: '主产品列表', method: 'get', url: '/serve-connect/api/v1/package/screen-product' })
  },

  getSubProductList() {
    return axios({ name: '子产品列表', method: 'get', url: '/serve-connect/api/v1/package/screen-remote' })
  },

  createPackage(data) {
    return axios({ name: '新增套餐', method: 'post', url: '/serve-connect/api/v1/package', data })
  },

  getDetail(id) {
    return axios({ name: '套餐详情', method: 'get', url: `/serve-connect/api/v1/package/${id}` })
  },

  outboundPackage(data) {
    return axios({ name: '套餐出库', method: 'post', url: '/serve-connect/api/v1/package/outbound', data })
  },

  getOutboundList(params) {
    return axios({ name: '出库记录', method: 'get', url: '/serve-connect/api/v1/package/outbound', params })
  },

  getComboList(params) {
    return axios({ name: '套餐组合列表', method: 'get', url: '/serve-connect/api/v1/package/combo', params })
  },

  getMainDeviceListOfCreateCombo(params) {
    return axios({
      name: '主设备列表（录入套餐用的选择框数据）',
      method: 'get',
      url: '/serve-connect/api/v1/package/combo/screen-device',
      params
    })
  },

  getTokenOfSearchSubDevice(data) {
    return axios({
      name: '获取搜索子设备token（录入套餐用）',
      method: 'post',
      url: '/serve-connect/app-api/v1/remote/token',
      data
    })
  },

  searchSubDeviceOfCreateCombo(token) {
    return axios({
      name: '轮询搜索网关子设备',
      method: 'post',
      url: `/serve-connect/app-api/v1/remote/quickBind/${token}`,
      headers: { error_message: false }
    })
  },

  deleteSubDeviceOfCreateCombo(id) {
    return axios({
      name: '移除子设备绑定',
      method: 'delete',
      url: `/serve-connect/app-api/v1/device/remote/${id}`
    })
  },

  enteringPackage(data) {
    return axios({
      name: '录入设备组合（套餐）',
      method: 'post',
      url: '/serve-connect/api/v1/package/combo',
      data
    })
  }
}
