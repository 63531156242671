import axios from '../request'

// 发现页管理相关接口
export default {
  // 栏目
  getColumnList(params) {
    return axios({ name: '栏目列表', method: 'get', url: '/serve-user/api/v1/course/classify', params })
  },

  editColumn(data) {
    return axios({ name: '编辑栏目', method: 'post', url: '/serve-user/api/v1/course/classify', data })
  },

  deleteColumn(id) {
    return axios({ name: '删除栏目', method: 'delete', url: `/serve-user/api/v1/course/classify/${id}` })
  },

  setColumnOnOff(id) {
    return axios({ name: '栏目开关', method: 'put', url: `/serve-user/api/v1/course/classify/${id}` })
  },

  // 文章
  getArticleList(params) {
    return axios({ name: '文章列表', method: 'get', url: '/serve-user/api/v1/course', params })
  },

  editArticle(data) {
    return axios({ name: '编辑文章', method: 'post', url: '/serve-user/api/v1/course', data })
  },

  deleteArticle(id) {
    return axios({ name: '删除文章', method: 'delete', url: `/serve-user/api/v1/course/${id}` })
  },

  setArticleOnOff(id) {
    return axios({ name: '文章开关', method: 'put', url: `/serve-user/api/v1/course/${id}` })
  }
}
