import axios from '../request'

// 购买记录相关接口
export default {
  getList(params) {
    return axios({ name: '购买记录列表', method: 'get', url: '/serve-order/api/v1/pkg-order', params })
  },

  reopen(data) {
    return axios({ name: '重新开通', method: 'post', url: '/serve-order/api/v1/pkg-order/reopen', data })
  },

  startRefund(data) {
    return axios({ name: '发起退款', method: 'post', url: '/serve-order/api/v1/pkg-order/startRefund', data })
  },

  rechargeCompleted(data) {
    return axios({ name: '充值完成', method: 'post', url: '/serve-order/api/v1/pkg-order/finish', data })
  }
}
