const Layout = () => import('@/views/layout/Layout.vue')

export default {
  path: '/home',
  hidden: false,
  meta: {
    title: '首页'
  },
  component: Layout,
  children: [
    {
      path: '',
      name: 'HomeIndex',
      hidden: false,
      meta: {
        title: '首页',
        keepAlive: false
      },
      component: () => import('@/views/home/Home.vue')
    }
  ]
}
