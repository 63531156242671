import Vue from 'vue'

import perm from './modules/perm'
import copy from './modules/copy'
import debounce from './modules/debounce'
import throttle from './modules/throttle'

const directives = {
  perm,
  copy,
  debounce,
  throttle
}

Object.keys(directives).forEach((key) => {
  Vue.directive(key, directives[key])
})
