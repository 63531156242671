// 接口的统一出口
import login from './modules/login'
import role from './modules/role'
import menu from './modules/menu'
import permission from './modules/permission'
import upload from './modules/upload'
import product from './modules/product'
import productFactory from './modules/product_factory'
import packageApi from './modules/package'
import subProduct from './modules/subProduct'
import order from './modules/order'
import purchaseRecord from './modules/purchaseRecord'
import device from './modules/device'
import deviceFactory from './modules/device_factory'
import storage from './modules/storage'
import firmware from './modules/firmware'
import developer from './modules/developer'
import user from './modules/user'
import app from './modules/app'
import account from './modules/account'
import supplier from './modules/supplier'
import channel from './modules/channel'
import automation from './modules/automation'
import voice from './modules/voice'
import discover from './modules/discover'
import download from './modules/download'
import other from './modules/other'

// 导出接口
export default {
  login,
  role,
  menu,
  permission,
  upload,
  product,
  productFactory,
  packageApi,
  subProduct,
  order,
  purchaseRecord,
  device,
  deviceFactory,
  storage,
  firmware,
  developer,
  user,
  app,
  account,
  supplier,
  channel,
  automation,
  voice,
  discover,
  download,
  other
}
