import axios from '../request'

// 渠道商管理相关接口
export default {
  getList(params) {
    return axios({ name: '渠道商账号列表', method: 'get', url: '/eage-admin/api/v1/users/checker', params })
  },

  editChannel(data) {
    return axios({ name: '创建渠道商账号', method: 'post', url: '/eage-admin/api/v1/users/wifi', data })
  }
}
