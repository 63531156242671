/**
 * @description: 防抖
 * @param {function} fn 要防抖的函数
 * @param {number} delay 延迟时间
 * @param {string} event 事件类型
 * 例：<el-button v-debounce="{ fn: test, delay: 500, event: 'click' }">test</el-button>
 * 传参时：<el-button v-debounce="{ fn: () => test(a, b, c) }">test</el-button>
 */
export default {
  inserted: function (el, binding, vnode) {
    const { fn, delay = 1000, event = 'click' } = binding.value

    let timer = null

    el.handler = () => {
      if (timer) clearTimeout(timer)

      timer = setTimeout(() => {
        fn()
        timer = null
      }, delay)
    }

    el.addEventListener(event, el.handler)
  }
}
