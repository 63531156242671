// 通讯方式
export const linkWays = [
  {
    value: '0',
    label: 'Wi-Fi'
  },
  {
    value: '1',
    label: '蓝牙BLE'
  }
]

// 配网方式
export const networkWays = [
  {
    value: 0,
    label: 'ap配网'
  },
  {
    value: 1,
    label: 'smartconfig配网'
  }
]

// 网关类型
export const gatewayTypes = [
  {
    value: 1,
    label: 'zigbee网关'
  }
]

// 设备状态
export const deviceStatus = [
  {
    value: -1,
    label: '离线'
  },
  {
    value: 0,
    label: '未绑定'
  },
  {
    value: 1,
    label: '已绑定'
  },
  {
    value: 2,
    label: '在线'
  }
]

// 订单状态
export const orderStatus = [
  {
    value: -1,
    label: '已取消'
  },
  {
    value: 0,
    label: '待生产'
  },
  {
    value: 5,
    label: '生产中'
  },
  {
    value: 10,
    label: '已完成'
  }
]

// 库存设备状态
export const storageDeviceStatus = [
  {
    value: 1,
    label: '已入库'
  }
]

// 遥控类型列表
export const remoteTypeList = [
  {
    value: '-1',
    label: '智能伴侣-夜灯'
  },
  {
    value: '-2',
    label: '延时'
  },
  {
    value: '-3',
    label: '推送'
  },
  {
    value: '1',
    label: '电视'
  },
  {
    value: '2',
    label: '空调'
  },
  {
    value: '3',
    label: '风扇'
  },
  {
    value: '4',
    label: '投影仪'
  },
  {
    value: '5',
    label: '机顶盒'
  },
  {
    value: '8',
    label: '灯'
  },
  {
    value: '10',
    label: 'IPTV'
  },
  {
    value: '11',
    label: '互联网盒子'
  },
  {
    value: '12',
    label: '热水器'
  },
  {
    value: '13',
    label: '空气净化器'
  },
  {
    value: '15',
    label: '暖风机'
  },
  {
    value: '16',
    label: '加湿器'
  },
  {
    value: '17',
    label: '智能窗帘'
  },
  {
    value: '18',
    label: '开关'
  },
  {
    value: '19',
    label: '智能插座'
  }
]

// 套餐类型
export const packageType = [
  {
    value: 1,
    label: '短信套餐'
  },
  {
    value: 2,
    label: '影像套餐'
  },
  {
    value: 3,
    label: '话费充值'
  }
]

// 套餐类型
export const telephoneType = [
  {
    value: 1,
    label: '移动'
  },
  {
    value: 2,
    label: '电信'
  },
  {
    value: 3,
    label: '联通'
  }
]

// 用户订单状态
export const userOrderStatus = [
  {
    value: 201,
    label: '已支付'
  },
  {
    value: 203,
    label: '已退款'
  },
  {
    value: 204,
    label: '退款中'
  },
  {
    value: 205,
    label: '退款失败'
  },
  {
    value: 901,
    label: '已完成'
  }
]

// 支付方式
export const payTypes = [
  {
    value: 2,
    label: '支付宝'
  },
  {
    value: 4,
    label: '微信支付'
  }
]
