import Vue from 'vue'
import VueRouter from 'vue-router'

import login from './modules/login'
import register from './modules/register'
import home from './modules/home'
// import product from './modules/product'
// import device from './modules/device'
// import developer from './modules/developer'
// import user from './modules/user'
// import firmware from './modules/firmware'
// import account from './modules/account'
// import setting from './modules/setting'

Vue.use(VueRouter)

// const Layout = () => import('@/views/layout/Layout.vue')

export const constantRoutes = [
  { path: '/', hidden: true, redirect: '/home' },
  login,
  register,
  home,
  // account,
  { path: '/404', hidden: true, component: () => import('@/views/error/404.vue') }
]

// const routes = [
//   { path: '/', hidden: true, redirect: '/product' },
//   // home,
//   login,
//   register,
//   product,
//   device,
//   developer,
//   user,
//   firmware,
//   account,
//   setting,
//   { path: '*', hidden: true, redirect: '/404' },
//   { path: '/404', hidden: true, component: () => import('@/views/error/404.vue') }
// ]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const router = new VueRouter({
  routes: constantRoutes
})

export default router
