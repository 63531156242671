import axios from '../request'

// 库存相关接口
export default {
  getList(params) {
    return axios({ name: '库存列表', method: 'get', url: '/serve-order/api/v1/p_order/serial', params })
  },

  putStorage(data) {
    return axios({ name: '设备入库', method: 'post', url: '/serve-order/api/v1/p_order/putStorage', data })
  },

  getPutStorageList(id) {
    return axios({ name: '订单入库记录', method: 'get', url: `/serve-order/api/v1/p_order/serial/${id}` })
  }
}
