import axios from '../request'

// 开发者相关接口
export default {
  getList(params) {
    return axios({ name: '开发者列表', method: 'get', url: '/eage-admin/api/v1/users', params })
  },

  deleteDeveloper(id) {
    return axios({ name: '删除开发者', method: 'delete', url: `/eage-admin/api/v1/users/${id}` })
  }
}
