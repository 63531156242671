import axios from '../request'

// 供货商管理相关接口
export default {
  getList(params) {
    return axios({ name: '供货商列表', method: 'get', url: '/serve-order/api/v1/supplier', params })
  },

  getDetail(id) {
    return axios({ name: '供货商详情', method: 'get', url: `/serve-order/api/v1/supplier/${id}` })
  },

  editSupplier(data) {
    return axios({ name: '供货商 新增/修改', method: 'post', url: '/serve-order/api/v1/supplier', data })
  },

  deleteSupplier(id) {
    return axios({ name: '删除供货商', method: 'delete', url: `/serve-order/api/v1/supplier/${id}` })
  },

  addSupplierProduct(data) {
    return axios({ name: '供货商添加产品', method: 'post', url: '/serve-order/api/v1/supplier/pro', data })
  },

  removeSupplierProduct(id) {
    return axios({ name: '供货商删除产品', method: 'delete', url: `/serve-order/api/v1/supplier/pro/${id}` })
  }
}
