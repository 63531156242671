import Vue from 'vue'
import Vuex from 'vuex'
// import createPersistedState from 'vuex-persistedstate' // Vuex 数据持久化插件

import user from './modules/user'
import permission from './modules/permission'
import detail from './modules/detail'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},

  mutations: {},

  actions: {},

  modules: {
    user,
    permission,
    detail
  }

  // plugins: [createPersistedState()] // 添加插件
})
